import React from 'react';
import { Link } from 'react-router-dom';

function Page() {
	return (
		<div className="grow flex flex-col gap-4 justify-center items-center overflow-hidden">
			<div className="bg-white rounded-md text-slate-800 px-6 py-5 w-full sm:max-w-5xl flex flex-col gap-4 justify-center items-center">
				<h1 className="text-xl font-bold text-left w-full">Personvernerklæring</h1>
				<div className="w-full flex flex-col divide-slate-800 gap-6">
					<div className="w-full">
						<div className="flex flex-col gap-3 mb-4">
							<h2 className="text-lg font-semibold">1 - Introduksjon</h2>
							<p className="text-slate-600">Bergan Technologies AS ("vi") er opptatt av at du skal ha tillit til oss og hvordan vi behandler dine personopplysninger. I denne personvernerklæringen forklarer vi derfor hvorfor vi samler inn informasjon om deg, hvordan vi bruker denne informasjonen og hvordan vi tar hensyn til ditt personvern.</p>
							<p className="text-slate-600">Personopplysninger er opplysninger og vurderinger som kan knyttes til en identifiserbar enkeltperson. Dette kan for eksempel være navn og kontaktinformasjon, kjøpshistorikk eller informasjon om interesser. </p>
							<p className="text-slate-600">Bruk av personopplysninger må følge personopplysningsloven. Vi, som behandlingsansvarlig, vil sørge for at vi bruker personopplysninger om deg i samsvar med lovens regler. Behandling av personlige data er nødvendig for at vi skal kunne betjene deg som kunde. </p>
							<p className="text-slate-600">Ved å gi oss dine personlige opplysninger, godtar du praksis og vilkår som er beskrevet i denne personvernerklæringen. Om du har innsigelser med hensyn til vår behandling av personvern har du også anledning å klage til Datatilsynet.</p>
							<h2 className="text-lg font-semibold">2 - Behandlingsansvarlig</h2>
							<p className="text-slate-600">
								Bergan Technologies AS
								<br />
								Kyrkjevegen 135
								<br />
								3803 BØ I TELEMARK
								<br />
								E-post: hello@bergantechnologies.com
								<br />
								Telefon: +47 99577729
								<br />
								Org nr: 827 391 522
							</p>
							<h2 className="text-lg font-semibold">3 - Hvilke personopplysninger har vi om deg</h2>
							<p className="text-slate-600">Opplysninger kan registreres og lagres når du registrerer deg på www.passvarsel.no, når du er i kontakt med salg og support enten via e-post, chat eller når du bruker våre tjenester.</p>
							<p className="text-slate-600">Avhengig av hvilken informasjon du gir oss, hvordan du bruker våre tjenester, og hvilke tillatelser du gir oss, har vi disse opplysningene om deg:</p>
							<ul className="list-disc list-inside">
								<li>
									<b className="font-semibold">Opplysninger om deg: </b>Informasjon du oppgir når du oppretter en brukerkonto, som e-postadresse og hvilke varsler du ønsker.
								</li>
								<li>
									<b className="font-semibold">Kjøpsinformasjon: </b>Informasjon vi får når du kjøper tjenester fra oss, som hva du har kjøpt, kortnummer og betalingsmetode (f.eks. Visa). Kjøpsinformasjonen tilknyttes din brukerkonto.
								</li>
								<li>
									<b className="font-semibold">Kommunikasjon: </b>Informasjon du gir oss når du har kontakt med support, som eposter sendt mellom oss.
								</li>
								<li>
									<b className="font-semibold">Opplysninger om bruk på nettsidene: </b>Informasjon vi får når du bruker vår nettside og app, som hva slags enhet du har (f.eks. mobil eller PC), IP-adresse, hvilke sider du besøker og hvilke funksjoner du bruker der.
								</li>
								<li>
									<b className="font-semibold">Opplysninger</b>Informasjon
								</li>
							</ul>
							<h2 className="text-lg font-semibold">4 - Hva bruker vi personopplysningene til</h2>
							<p className="text-slate-600">Vi bruker personopplysningene dine til følgende formål: </p>
							<ol className="list-decimal list-inside">
								<li>
									<b className="font-semibold">Tilby nettside og app: </b>Vi bruker opplysninger for å tilpasse vår nettside og app til det tekniske utstyret du bruker. Vi kan også bruke opplysningene til å tilpasse innholdet på vår nettside og app til hva vi tror du er interessert i.
								</li>
								<li>
									<b className="font-semibold">Varsler: </b>Vi bruker opplysninger for å gjennomføre ditt kjøp av varslingstjenesten, bl.a. for å sende deg varslene, sende bekreftelse og lagre dine varselinstillinger.
								</li>
								<li>
									<b className="font-semibold">Opprette brukerkonto: </b>Vi bruker opplysninger dersom du oppretter brukerkonto, bl.a. for å gi deg tilgang med brukernavn og passord, for å lagre din kontaktinformasjon og betalingsinformasjon.
								</li>
								<li>
									<b className="font-semibold">Support: </b>Vi lagrer opplysninger som du gir og mottar dersom du har kontakt med oss for å yte service og administrere ditt kundeforhold.
								</li>
								<li>
									<b className="font-semibold">Statistikk: </b>Vi utarbeider statistikk og kartlegger markedstrender for å forbedre og videreutvikle våre produkter og tjenester. Så langt det er mulig gjør vi dette med anonyme opplysninger, uten at vi vet at informasjonen er knyttet spesifikt til deg.
								</li>
							</ol>
							<p className="text-slate-600">Det rettslige grunnlaget for formål 1, 4 og 5 er vår berettigede interesse i å kunne tilby, videreutvikle og markedsføre våre tjenester. </p>
							<p className="text-slate-600">Det rettslige grunnlaget for formål 2, 3 og 4 er for å oppfylle vår avtale med deg. </p>
							<h2 className="text-lg font-semibold">5 - Hvem deler vi personopplysninger med?</h2>
							<p className="text-slate-600">Vi deler personopplysninger med selskaper som leverer tekniske og administrative tjenester til oss, slik som IT-leverandører. Vi har avtaler med disse for å sikre ditt personvern.</p>
							<p className="text-slate-600">Vi kan utlevere opplysninger i enkelttilfeller, eksempelvis til offentlige myndigheter, ved mistanke om lovbrudd.</p>
							<p className="text-slate-600">Hvis du samtykker, kan vi dele dine personopplysninger med våre samarbeidspartnere som beskrevet i ditt samtykke. Dette kan f.eks. være SMS tjenester. </p>
							<h2 className="text-lg font-semibold">6 - Hvor lagrer vi informasjon om deg – og hvor deler vi informasjonen om deg med andre?</h2>
							<p className="text-slate-600">Vår inloggingsløsning og database er levert av Google Firestore og data lagres i deres skytjenester hos Google Cloud sitt datasenter i Frankfurt, Tyksland.</p>
							<p className="text-slate-600">Noen av underleverandørene vi bruker:</p>
							<ul className="list-disc list-inside">
								<li>
									<b className="font-semibold">Stripe: </b>Når man betaler for tjenester, vil betalingsinformasjon lagres hos Stripe. Dette inkluderer kunde ID, e-postadressen, og kortinformasjon.
								</li>
								<li>
									<b className="font-semibold">Google Workspace: </b>Google Workspace benyttes blant annet til håndtering av e-post, dokumenter (G Disk). Informasjon lagres i disse systemene og e-postkorrespondanse eller dokumenter kan være lagret hos Google Workspace.
								</li>
								<li>
									<b className="font-semibold">Google Firebase: </b>Google Firebase er stedet hvor alt av dataen lagres. Vår data er lagret på Google sine datasenter i Frankfurt, Tyskland. Firebase håndterer også brukerkonto systemet.
								</li>
								<li>
									<b className="font-semibold">Gatewayapi: </b>Gatewayapi brukes til å sende ut SMS. Der lagres data som ditt telefonnummer og innholdet i meldingene du mottar.
								</li>
								<li>
									<b className="font-semibold">Google Analytics: </b>Google Analytics benyttes for analyse av trafikk. IP-adresser og besøksmønster lagres.
								</li>
							</ul>
							<h2 className="text-lg font-semibold">7 - Kommunikasjon gjennom e-post og sms</h2>
							<p className="text-slate-600">Som en kunde vil du, motta e-post og SMS med informasjon om ditt kundeforhold. Eksempel er ved aktivering av brukerkontoen din og varsler som imøtekommer innstillingene du har valg. Dette er definert som berettiget interesse og det vil ikke bes om samtykke til.</p>
							<h2 className="text-lg font-semibold">8 - Oppbevaring og sletting</h2>
							<p className="text-slate-600">Vi lagrer opplysninger om deg, kun så lenge det er nødvendig for å oppnå formålet de ble innhentet for, eller dersom vi er pålagt å lagre opplysningene. Opplysningene vil slettes eller anonymiseres når de ikke lengre er nødvendige for å oppnå formålet.</p>
							<h2 className="text-lg font-semibold">9 - Innsyn og retting</h2>
							<p className="text-slate-600">Du har krav på innsyn i de opplysninger som er registrert om deg. Innsyn kan også fås ved å sende en henvendelse til hello@bergantechnologies.com. Dersom de registrerte opplysninger ikke er riktige eller er ufullstendige, kan du kreve at opplysningene korrigeres i henhold til personopplysningsloven. </p>
							<h2 className="text-lg font-semibold">10 - Informasjon om bruk av informasjonskapsler</h2>
							<p className="text-slate-600">En informasjonskapsel (cookie) er en liten tekstfil som lagres på din datamaskin.</p>
							<p className="text-slate-600">Vi bruker cookies på våre nettsider for å sikre funksjonalitet på nettsiden, analysere nettrafikk og se hvilke områder på vårt nettsted du har besøkt. </p>
							<p className="text-slate-600">Når du besøker våre tjenester, setter vi både midlertidige og varige informasjonskapsler. En midlertidig informasjonskapsel slettes ofte automatisk når du lukker nettleseren din, mens varige kan ligge på din maskin i opptil ett år.</p>
							<p className="text-slate-600">Om du ønsker å lære mer om informasjonskapsler kan http://www.aboutcookies.org benyttes.</p>
							<p className="text-slate-600">I henhold til norsk lov har du automatisk samtykket i at vi lagrer informasjonskapsler på din datamaskin dersom du tillater dette gjennom innstillingene i din nettleser.</p>
							<h2 className="text-lg font-semibold">11 - Endringer i personvernerklæringen</h2>
							<p className="text-slate-600">Vi vil kunne oppdatere personvernerklæringen fra tid til annen. Du vil få beskjed om endringer av betydning. Du vil alltid finne siste versjon av vår personvernerklæring på nettsiden/appen.</p>
							<h2 className="text-lg font-semibold">12 - Kontaktinformasjon</h2>
							<p className="text-slate-600">Hvis du ønsker å komme i kontakt med oss, kan du benytte følgende kontaktinformasjon: </p>
							<p className="text-slate-600">
								Kontaktperson: Ole Halvor Lindtveit Bergan
								<br />
								Bedrift: Bergan Technologies AS
								<br />
								Adresse: Kyrkjevegen 135, 3803 BØ I TELEMARK
								<br />
								E-post: hello@bergantechnologies.com
								<br />
								Telefon: +47 99577729
								<br />
								Org nr: 827 391 522
							</p>
						</div>

						<Link to="/" className="text-slate-800 font-semibold">
							Klikk her for å gå tilbake
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Page;
