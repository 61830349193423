import React, { Fragment, useState, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PhoneIcon } from '@heroicons/react/outline';
import { functions } from '../Firebase';
import { httpsCallable } from 'firebase/functions';

function Component(props) {
	const cancelButtonRef = useRef(null);
	const [codeSent, setCodeSent] = useState(false);
	const [verificationcode, setVerificationcode] = useState('');
	const [phonenumber, setPhonenumber] = useState('');
	const [error, setError] = useState('');

	function sendVerificationCode() {
		if (phonenumber === '') {
			setError('Ugyldig telefonnummer');
		} else {
			var sendPhoneVerificationCode = httpsCallable(functions, 'sendPhoneVerificationCode');
			sendPhoneVerificationCode({ phone: phonenumber }).then((result) => {
				console.log(result);
				if (result.data.success === true) {
					setError('');
					setCodeSent(true);
				} else {
					setError('Noe gikk galt, skrev du telefonummeret riktig?');
				}
			});
		}
	}

	function checkVerificationCode() {
		if (verificationcode === '') {
			setError('Ugyldig engangskode');
		} else {
			var checkVerificationCode = httpsCallable(functions, 'checkVerificationCode');
			checkVerificationCode({ code: verificationcode }).then((result) => {
				console.log(result);
				if (result.data.success === true) {
					setError('');
					props.setOpen(false);
				} else {
					console.log(result);
					setError('Noe gikk galt, skrev du telefonummeret riktig?');
				}
			});
		}
	}

	return (
		<Transition show={props.open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} open={props.open} onClose={() => props.setOpen(false)}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Dialog.Overlay className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-all backdrop-blur-sm" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
									<PhoneIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
								</div>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Legg til telefonnummer
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm text-gray-500">Du vil motta en engangskode for å bekrefte at telefonnummeret tilhører deg.</p>
									</div>
									<div className={codeSent === true ? '' : 'hidden'}>
										<label htmlFor="verificationcode" className="text-sm font-medium text-slate-700 mt-3 mb-1 hidden sm:block">
											Engangskode
										</label>
										<input id="verificationcode" onChange={(val) => setVerificationcode(val.target.value)} name="verificationcode" type="text" placeholder="Engangskode" minLength="6" maxLength="6" required className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-base sm:text-sm text-center sm:text-left mt-5 sm:mt-0" />
									</div>
									<div className={codeSent === true ? 'hidden' : ''}>
										<label htmlFor="phonenumber" className="text-sm font-medium text-slate-700 mt-3 mb-1 hidden sm:block">
											Telefonnummer
										</label>
										<input id="phonenumber" onChange={(val) => setPhonenumber(Number(`47${val.target.value}`))} name="phonenumber" type="tel" placeholder="Telefonnummer" pattern="[0-9]{3} [0-9]{2} [0-9]{3}" maxLength={8} required className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-base sm:text-sm text-center sm:text-left mt-5 sm:mt-0" />
									</div>
								</div>
							</div>

							<div className="mt-3 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
								<button type="button" className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm ${codeSent === true ? '' : 'hidden'}`} onClick={checkVerificationCode}>
									Sjekk engangskode
								</button>

								<button type="button" className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm ${codeSent === true ? 'hidden' : ''}`} onClick={sendVerificationCode}>
									Send engangskode
								</button>

								<button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => props.setOpen(false)} ref={cancelButtonRef}>
									Avbryt
								</button>
							</div>

							{error ? <p className="mt-3 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex text-red-400 text font-semibold sm:text-sm">{error}</p> : <></>}
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

export default Component;
