import React, { Fragment, useState, useEffect } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

const stations = [
	{
		name: 'Ingen varsling',
		id: null,
	},
	{
		name: 'Grenland politistasjon',
		id: '335abadd312dc5db2bf1031cea65e0cc4f93a5f3f96bfc794d7e874ffda0d9cc',
	},
	{
		name: 'Politiets publikumssenter i Stokke',
		id: 'fa34afc5c058656523a31c697ba28ea05dcfe702aec415fbb80d661a6d7e80e8',
	},
	{
		name: 'Drammen politistasjon',
		id: '0ea2394ac17c4220b28c724c32ef0284ef2405b1df7fdbab24e98d699545b24a',
	},
	{
		name: 'Hønefoss politistasjon',
		id: '5aff634708c9e2ad46ebc0b2a8eda7a21cc0b277b0ebbed29fbb16c1814a0911',
	},
	{
		name: 'Midtre Hallingdal politistasjon',
		id: 'a8022e3e8f1bcb2ceb91c214e4f6eaa9f6fbf9e74c4166f2268935da0fd86792',
	},
	{
		name: 'Notodden politistasjon',
		id: 'c4dfc82abc32b9e5994d902cd42ed0eac1d8e0513a369b841b021cf1e21b5edb',
	},
	{
		name: 'Kongsberg politistasjon',
		id: '3fd68885eaa1fd188c56838fb476d4a8da7a02a1727d1a3c5842e0949f11b324',
	},
	{
		name: 'Arendal og Froland politistasjon',
		id: '888a8097cecf2eccb6c811e8a2d86ebfd272d6fdb2f59c8afd8a4d8042cfd898',
	},
	{
		name: 'Farsund og Lyngdal politistasjon',
		id: 'aaa47bdec3a7a5ca76b6ef38e9991caf849eca10a3a0841c5abaa70fffcea812',
	},
	{
		name: 'Kristiansand politistasjon',
		id: '68f03a0e2ec3c81ca34767e587b5ec7519204b03bb41ed1b749ecb6067a2d016',
	},
	{
		name: 'Alta politistasjon',
		id: '3279363f9212c599307e3fdc1eae5cb3a9eed1eca5c2b42c13878c39a2328326',
	},
	{
		name: 'Hammerfest politistasjon',
		id: '86ee90efe98936ecd296400637aad2dfbf024d05445d37073ddf18909a54e31a',
	},
	{
		name: 'Kirkenes politistasjon',
		id: '642d111a334724d19e7a45275bd82cf549c2ec161e8dc59bc79f5c1e7b585b02',
	},
	{
		name: 'Vadsø politistasjon',
		id: '7f245e3dc515623718e39be3dfde6eaeb1055bbede600c7f31222c5211606c61',
	},
	{
		name: 'Porsanger politistasjon',
		id: '19d6ac44b86750231306a49518146d7a0bba28bcac9bfb040a9672bd9c7dd51a',
	},
	{
		name: 'Tana politistasjon',
		id: 'fddce9c2769251cb9aa2704ad8f4ce8e5296516e4889e686d9fbc441f444f2ef',
	},
	{
		name: 'Elverum politistasjon',
		id: '12b2d60319641ea9240b00a4baa7568988b7af327d8ab5a5596f2a2ef496f22a',
	},
	{
		name: 'Fagernes politistasjon',
		id: '1da3d407da965ec0f39af67d36275f4b7106c7a5ea5b386209b9ec843ec7ac5e',
	},
	{
		name: 'Gjøvik politistasjon',
		id: '444a3fac353a44ab43bdc6d2b832a25d4ade4230dde93db2f92215d7faabb554',
	},
	{
		name: 'Gran politistasjon',
		id: '65f59672ce9b839e2ebfa29137da0c7843ed4b39d401ed47c623e2c2f23697aa',
	},
	{
		name: 'Hamar politistasjon',
		id: 'a4bbbc39e65113ba59720574294a97734d3e809b5c53d6decc9be5dbdea65a56',
	},
	{
		name: 'Kongsvinger politistasjon',
		id: 'cfdc05f9ee15105147bba99804ee747092517b5be0c7676fd3816cd4238308f0',
	},
	{
		name: 'Lillehammer politistasjon',
		id: 'a2a38ea1aaf8a6f7ff54c508993a691a2992ed049048fff085d069476ab7d136',
	},
	{
		name: 'Otta politistasjon',
		id: 'fb7d91b9580c2bc0f3dd6320bc0c90b28d76d8a3cfb77ab312599f678028e4f7',
	},
	{
		name: 'Tynset politistasjon',
		id: '5272fd5b368f4de0553e32502aaafc54a93c24d8ebe78828678ad45d40914410',
	},
	{
		name: 'Ålesund politihus',
		id: '8014a727faa2b1779bf9f458ae2614f4560b776a18a5549aace8fa46ee589803',
	},
	{
		name: 'Kristiansund politistasjon',
		id: '365455ec9b2ceb64162009398f03c86535ed472158de6aca5bd54203a2ce1822',
	},
	{
		name: 'Molde politistasjon',
		id: '7061dd329fe740521cf2f695221eed61d664049076fc9b3323b2ca784b0d2017',
	},
	{
		name: 'Sykkylven politistasjon',
		id: 'e1ac01e9138e4d985387c08b04462c6065f44e24d8434fb6adc5cd7d98ba5df2',
	},
	{
		name: 'Ulstein og Hareid politistasjon',
		id: '8729694f74816364f5db30bfce214cd4bc7d1f0b505c06c32850547097a78d03',
	},
	{
		name: 'Volda og Ørsta politistasjon',
		id: '5b3874d74f38e4cb5f2d64d9de5fddb6ce092980a0c49d5ef89eae0eeda2a268',
	},
	{
		name: 'Sunndal politistasjon',
		id: 'df0b6026a36bab2bea29216f1564eeebd539b8c6c78761a05249ee643a22847c',
	},
	{
		name: 'Surnadal politistasjon',
		id: '03779b424121f91216ece5b5e2256c89906408012f8ca48f3d87de194b41c00c',
	},
	{
		name: 'Bodø politistasjon',
		id: 'f52ba1f28907d88cc45c9453daa273148c2f990808a1eba8a6f507deef4a723a',
	},
	{
		name: 'Brønnøy og Vevelstad politistasjon',
		id: '282ca2e6afc0a69740a3a8d8b0f6087ae441da6cc1ea1c3d1303d94037091194',
	},
	{
		name: 'Mo I Rana politistasjon',
		id: '4aa7d11760d8767f8e9ee79a361c4d1ac9f7857e104bb0a11b07a3a74a8ef654',
	},
	{
		name: 'Mosjøen politistasjon',
		id: 'a5eebb15f706873c7399e7d52f0c98d400fe8e0ef4ac3ea875124e4012585466',
	},
	{
		name: 'Narvik politistasjon',
		id: '71d39130b055229bc22fe182dba7204785552181a54b5b4bd710fd89287394b8',
	},
	{
		name: 'Sortland politistasjon',
		id: '85def6f1c2ba1e1076cba148d827e9b3a67856e1ec1acc955d5cf982d551eb09',
	},
	{
		name: 'Svolvær politistasjon',
		id: '48b70ed5ac92129401b79739660cab3f5879782b90175455b4a9da490b025aec',
	},
	{
		name: 'Alstahaug og Leirfjord politistasjon',
		id: '87b817ccaa51b6f29256aeb0a1ab883b47e6a08434ebf8813b4e61d413e5199c',
	},
	{
		name: 'Fauske politistasjon',
		id: 'eade94a3087e41510ecf889df3995032fee74831c13c868ade0b23ef40be04f1',
	},
	{
		name: 'Bærum politistasjon',
		id: 'e8cfd353ad2e1f03432faa6d1c1ea3401102eb0bf7e8f9e9fe0e2607f867a8a0',
	},
	{
		name: 'Grønland politistasjon',
		id: 'c4afbbf5b8fea8ae5b5749370eaa26ae1c405797f39e8b6bfec5f3f96255451e',
	},
	{
		name: 'Sysselmesteren på Svalbard',
		id: '14b88262bddc845e69546fe29ed75e0d8d5000e076becafcb6dd6fa65afb9917',
	},
	{
		name: 'Eigersund politistasjon',
		id: '8ff00779c318c471c0d414cd1f055e1849779b1887131129362daa1ae82b116b',
	},
	{
		name: 'Politiets Forvaltningssenter i Haugesund',
		id: 'a3a8a5a4e819768c11bc634656e559f2b94a2f221543c2964e30cd94b0d389fa',
	},
	{
		name: 'Randaberg politistasjon',
		id: '140c6b350cf1d4175f6aa11f1e3a0d4e9c6af47520e1a1cbd0cc5ab9fd1a4cd6',
	},
	{
		name: 'Sandnes politistasjon',
		id: 'f9753b40458d422073a333656ab704b027933bf91dc32914b85486b85a15ffda',
	},
	{
		name: 'Stavanger lufthavn Sola',
		id: '520e946da48c4bacfd07370019d42c916c2c740c5aa72f1c2643a6e8aad8a1e1',
	},
	{
		name: 'Stord politistasjon',
		id: '7eb5437e98af3d28a65b1c6df36ca2f548885d66b2bfb911fa4c38e3d8f6c3d4',
	},
	{
		name: 'Etne og Vindafjord politistasjon',
		id: '0658e32534f0e4ceeff77edeffae2e641c40cedc6a9edc27c40d260968e07f23',
	},
	{
		name: 'Bardu politistasjon',
		id: '58fd1fc0f9c3169e1d365575511cfb339c50b5db5cb26113bc95592fc9636a68',
	},
	{
		name: 'Finnsnes politistasjon',
		id: '55416a725399ddbb52fff9f70f317f6d339275059bdcb9f32f3b1b03a268f5a6',
	},
	{
		name: 'Harstad politistasjon',
		id: 'e3b843cf9121ea50d874bc361ca10db27868c625454d30962b79af08ae73780b',
	},
	{
		name: 'Nordreisa og Kvænangen politistasjon',
		id: '4cae8fc9840ef4c8b84fe0f556f8a09ca36aa4e17948c29e9e30e2e579f8f94d',
	},
	{
		name: 'Tromsø politistasjon',
		id: 'cdf7f5063cfb8065a3e2e4adf33e32c656ef0480de5e7a0f068d534328acc612',
	},
	{
		name: 'Heimdal politistasjon',
		id: 'a5cb8090ab3cadc95b8e94fc2f90c7700e391b9fc0d2a1a31cbd54157d86eff1',
	},
	{
		name: 'Namsos politistasjon',
		id: 'e2f5e5f4ea8df89d50fc3caef3705519ce697b22f11f466ef86ddf2ca78aa436',
	},
	{
		name: 'Oppdal politistasjon',
		id: '899cf7b06941d67e79601fe02f9274bf66018118efed29ed0d3c98dd853a5cb2',
	},
	{
		name: 'Orkdal og Agdenes politistasjon',
		id: '6aabb5497793a7a45f44af72aecb111f6da3562730e2da49630fc2760ec539ef',
	},
	{
		name: 'Ørland politistasjon',
		id: 'f90a0285bf78575f5d20203d32def444b8fb8a4c99cc22848b1e75719bee0a1b',
	},
	{
		name: 'Steinkjer politistasjon',
		id: '3c6ac4c3882e046c05c6ee30cc4839e405761906cce3be09f693aafde90b3332',
	},
	{
		name: 'Stjørdal politistasjon',
		id: 'f687c6a208976fe6ac5f3b1966299e348fe537e59ba4e39dd209f8b6bd0e5398',
	},
	{
		name: 'Trondheim sentrum politistasjon',
		id: 'a7d1ce445762e13d9634fe8263262fd2faf362877741916a67ee82e26abd121b',
	},
	{
		name: 'Nærøy politistasjon',
		id: '29333c2a1ac07acbca01e3cbb684c604e9be0d96dd306b207e1ebb3b06fbe74b',
	},
	{
		name: 'Bergen Vest politistasjon',
		id: 'd7b7dfe29e507f78dff90f35d540095f4d4eea1a78be9b1299b375e7ca30f227',
	},
	{
		name: 'Stad politistasjon',
		id: 'f72f0ae488a3f26e55634ff81d128149b96c4be593eb343955087d1448c15a0b',
	},
	{
		name: 'Florø Politistasjon',
		id: '43ee435022fb728fc94a96e172b137017a4cf3954c17676a6f379151b1e73613',
	},
	{
		name: 'Førde politistasjon',
		id: '38d51e979f13602dd0da0c5a9956482251be3ec1a5e45db63e046fb6f264d4b7',
	},
	{
		name: 'Hardanger politistasjon',
		id: '44bfcd71ead8cedb06cfac8966d899d89b1f13d6ed98cfc62e0b682dab870981',
	},
	{
		name: 'Nordhordland politistasjon',
		id: '407d47f595a2e79871a88e57359f789cd7b9bdc2dd8f747136d51c6e0ee2aa30',
	},
	{
		name: 'Sogndal politistasjon',
		id: '9f3f7cf45ab25856306d9d5a43a4bfc7bc03e7b2aee86a53068e03eedfc87997',
	},
	{
		name: 'Øygarden politistasjon',
		id: '7e2786ecaf5ddd38194319b37b9c61528c7c8245c3ecba4c62828f14eff94e17',
	},
	{
		name: 'Voss politistasjon',
		id: '9d9f6c7a34c8fe900c987ffa4caf1b7302b4e86561373878c7e782c53f4dd60f',
	},
	{
		name: 'Bjørnafjorden politistasjon',
		id: '7739c0f2a5d78841ea0c6e94053d75d64efbeb2a4a0ec0ba281fb33c851d5761',
	},
	{
		name: 'Politiets publikumssenter i Ski',
		id: 'b56db719fe3d46ba2d691e97b8dc58ff99bf66e93980ac01c0b88e7c386d7fac',
	},
	{
		name: 'Grålum',
		id: 'd4fd948139a6bb3c88d56ba8bb259ed35ba5f4dec5882e82528624312de37b78',
	},
	{
		name: 'Lillestrøm politistasjon',
		id: '9362230d4803607d0f874f958a554d0d46642980c7917856a34fa46786acd48e',
	},
	{
		name: 'Mobilt pass- og ID-kontor Sommarøy',
		id: '4f4dbdd5873da8309159d54ddc4f6187d7256c0774dddec371e9182533ec54f5',
	},
	{
		name: 'Mobilt pass- og ID-kontor Tromvik',
		id: '90c7e542b1cf3f9b1ae3f627ff6dfdc47ade28d761f2019c01db35f977e8c568',
	},
	{
		name: 'Mobilt pass- og ID-kontor Hansnes',
		id: '2bf06fe94d00f40bf9518208538ce52eaa6ef35de789bab8e77d94016391a3aa',
	},
	{
		name: 'Mobilt pass- og ID-kontor Nordkjosbotn',
		id: '5c69f8942585b0d02b192bc916a299fa7861b03d8f417c8b867a9d3b8331d0e8',
	},
	{
		name: 'Mobilt pass- og ID-kontor Lyngseidet',
		id: '268b0a8c77e86fe263634f4155a4279369aa990d4759b0863feacb64b9d2ca51',
	},
	{
		name: 'Mobilt pass- og ID-kontor Skibotn',
		id: 'b6065435587eac057151f2ed9858a624d9d94e61e625d997bd78042269c5fdab',
	},
	{
		name: 'Mobilt pass- og ID-kontor Burfjord',
		id: '685b8fa7577aa1a6459c0a717b9a6428a804435437f9938b1deb52547128e90c',
	},
	{
		name: 'Mobilt pass- og ID-kontor Gryllefjord',
		id: 'e16871d68096d8f45095139a4eb06cc70e4a21707296e448b1e7eb42d6ccdb82',
	},
	{
		name: 'Mobilt pass- og ID-kontor Skjervøy',
		id: 'fe867b58f306d7709ffd3d61f214473de7f4c73ddcdfdf1e24d9b6b5644732e1',
	},
	{
		name: 'Mobilt pass- og ID-kontor Malangen',
		id: '235d6f759c8cc56a646de540642ed1e333735760e47fff076d87a3d423c4f94d',
	},
	{
		name: 'Mobilt pass- og ID-kontor Øksfjord',
		id: '9480249118fa5d5a11b374f855a31f37a45a8c70a0ba2f699f61bf7f1fd69422',
	},
	{
		name: 'Mobilt pass- og ID-kontor Honningsvåg',
		id: 'f7111d6c0602b9bd4ec5355cf37de2a025e6b599ef9930d75c9d52383aaba585',
	},
	{
		name: 'Mobilt pass- og ID-kontor Kjøllefjord',
		id: '266754180f5dd833a8a4258a7a9de05d2fd2f2473c61aa08eb220cef5bd11235',
	},
	{
		name: 'Mobilt pass- og ID-kontor Båtsfjord',
		id: '4710902b7a5d218cb2e4241a3e99afc831dfe76592b4835b3dee82966a74cc9d',
	},
	{
		name: 'Mobilt pass- og ID-kontor Kautokeino',
		id: '89802384b11de9107144768ae42e07bf222b80aaae1156fe20ad55afd78f2a83',
	},
	{
		name: 'Mobilt pass- og ID-kontor Havøysund',
		id: 'a7b1721150475f30bf44f30ee35033144007d598370e0a92fa2dfebd2687e375',
	},
	{
		name: 'Mobilt pass- og ID-kontor Andenes',
		id: 'c9dfff7e10b44fb0891578bd498b5fed289a450f5c5eedb323c1589c186a538b',
	},
	{
		name: 'Mobilt pass- og ID-kontor Leknes',
		id: '6144053a90086f3b7ad555dc5b46b79738b79b281969900fb5d5f8751c20236a',
	},
	{
		name: 'Mobilt pass- og ID-kontor Innhavet',
		id: '7c49ed12f3f6cf228bb62a0613883ac527715b6b694b229be0d7654a01e4d66f',
	},
	{
		name: 'Mobilt pass- og ID-kontor Ørnes',
		id: '07adbf66481a4c1e6daa4f9108db8592618ed9614b8a4646d130d1dabdac8825',
	},
	{
		name: 'Mobilt pass- og ID-kontor Leinesfjorden i Steigen',
		id: 'bd41a65a2a9e4b8722453cd7f48642d2e632ea26ca346721164dc286d1a8e2de',
	},
	{
		name: 'Mobilt pass- og ID-kontor Sjøvegan',
		id: '66a8dcd57473c36543ce7abdcf0c3a33f327e8028d7b70b3d2b1ed3f7d0827f4',
	},
	{
		name: 'Mobilt pass- og ID-kontor Ibestad',
		id: '34e44e1a8432475e48fa4a49fa186febaaa098fe79c4d09052a4500c675db112',
	},
	{
		name: 'Mobilt pass- og ID-kontor Høyanger',
		id: '125abfc78bd6ffada3ecae12df6e45cbf022e964800cd49f8be65dc9908861af',
	},
	{
		name: 'Mobilt pass- og ID-kontor Vik',
		id: 'cc8b5449a3ead406488a9a8a7294cf8e634b8d6ab49fabc7dc1e1ca84e7b667b',
	},
	{
		name: 'Mobilt pass- og ID-kontor Lærdal',
		id: 'f558afb4202501f63cf6c4b547c93eee2ada33a44f5efaac4d69e69810e5fd95',
	},
	{
		name: 'Mobilt pass- og ID-kontor Vanylven',
		id: '6d98a27c906cdb9af30d6d76c22dca8dd791438b85d0e34ae3fc54bf91dcfcbd',
	},
	{
		name: 'Mobilt pass- og ID-kontor Valldal',
		id: '3c335ac9b13be937a1e8ad5f58f2b0239380476af3b1167867c2cdf26d315305',
	},
	{
		name: 'Mobilt pass- og ID-kontor Rauma',
		id: '93538d0402428e69b17f081190cf58af3237216c1672c30eada44eceabe6d6a2',
	},
	{
		name: 'Mobilt pass- og ID-kontor Smøla',
		id: '775f75e70caecc7e34ccebfae37abb66acc40aa68fc1ced2cb0a86a1538de546',
	},
	{
		name: 'Mobilt pass- og ID-kontor Hitra',
		id: '3f74c088f21ef01241e16441c64418f0fef80c1b031c21acfe96c12cd9f2ff1f',
	},
	{
		name: 'Mobilt pass- og ID-kontor Grong',
		id: '5277f863cf3a93cddc8e82c702eace1425e51c2a5fbe1d37d1ab8f562bbcb752',
	},
	{
		name: 'Mobilt pass- og ID-kontor Tydal',
		id: 'e94aa9ef71135d02c456ad4df215bd328dcbd467b611110b77b81938419c18a0',
	},
	{
		name: 'Mobilt pass- og ID-kontor Røros',
		id: 'dc147da9d199c56eaef8cbdd339eba23f5a4077280d8dbaf7db4ebe0b6646c7e',
	},
	{
		name: 'Mobilt pass- og ID-kontor Lom',
		id: 'c8441be8d1b07673674e14c08a715a5bf6b4ec8a1d7bfe1b6a6d50ea50cec835',
	},
	{
		name: 'Mobilt pass- og ID-kontor Nore og Uvdal',
		id: '6b5648c6618bbb2269455b65a30c2f887ba785b09a0c702f097e656a844a9ab2',
	},
	{
		name: 'Mobilt pass- og ID-kontor Rjukan',
		id: '972c41a113325e2f43d94c08ea1fbe6958cd458917774b028fc9fc3128b36e62',
	},
	{
		name: 'Mobilt pass- og ID-kontor Vinje',
		id: 'eafd6cf4198ff66dcea604a683b44370645eedbeddd7a76b789504eeda1c76ee',
	},
	{
		name: 'Mobilt pass- og ID-kontor Valle',
		id: '03053a2db36d226c9ad2a5b13962c838cceec3c87f7f80dcc1c794e2ea681ae5',
	},
	{
		name: 'Mobilt pass- og ID-kontor Evje',
		id: 'b9175bbdc3d651f5bf376041fdaccea56545b77078f00446c592faaa69742a4d',
	},
	{
		name: 'Mobilt pass- og ID-kontor Hjelmeland',
		id: 'c603645902c4edecad0474f704b0e2311db6c24e6dc98e6016ea03dc1e0c4f28',
	},
	{
		name: 'Mobilt pass- og ID-kontor Utsira',
		id: '434ec7cc66cf1014165595a063d30f7007f6c467747ec40fbe0559e6c5bab1e0',
	},
	{
		name: 'Mobilt pass- og ID-kontor Austevoll',
		id: 'fc79316835c4b116d253b31f4ed9035be312b1784163e3017e0e2ee9d10bda6c',
	},
	{
		name: 'Mobilt pass- og ID-kontor Norheimsund',
		id: '01a593d92c647d07a2d1a5fec55ccb35992b3df02efadc94ff8d7f71b15e70c3',
	},
	{
		name: 'Mobilt pass- og ID-kontor Husnes',
		id: 'b94e86ee8180fcd6fee73eb440f2e7b4c3f291af7899425f0e968fdaed4107a7',
	},
];

function StationSelect(props) {
	const [selected, setSelected] = useState(
		props.currentStation
			? stations.filter((station) => {
					return station.id === props.currentStation;
			  })[0]
			: stations[0]
	);

	/* stations.filter((station) => {
		return station.id === props.currentStation;
	})[0]; */

	const [query, setQuery] = useState('');

	const filteredPeople = query === '' ? stations : stations.filter((person) => person.name.toLowerCase().replace(/\s+/g, '').includes(query.toLowerCase().replace(/\s+/g, '')));

	useEffect(() => {
		setSelected(
			props.currentStation
				? stations.filter((station) => {
						return station.id === props.currentStation;
				  })[0]
				: stations[0]
		);
	}, [props.currentStation]);

	return (
		<div className="w-full">
			<Combobox
				value={selected}
				onChange={(v) => {
					setSelected(v);
					props.onSelect(v.id);
				}}
			>
				<div className="relative mt-1">
					<div className="relative w-full text-left appearance-none border border-gray-300 bg-white rounded-md shadow-sm cursor-default outline-none text-sm overflow-hidden">
						<Combobox.Input className="w-full border-none focus:ring-0 py-2 pl-3 pr-10 text-sm leading-5 text-slate-700 appearance-none outline-none" displayValue={(station) => station.name} onChange={(event) => setQuery(event.target.value)} />
						<Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
							<SelectorIcon className="w-5 h-5 text-black" aria-hidden="true" />
						</Combobox.Button>
					</div>
					<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery('')}>
						<Combobox.Options className="z-40 absolute w-full py-1 mt-1 overflow-auto border border-gray-300 bg-white rounded-md shadow-sm max-h-40 ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
							{filteredPeople.length === 0 && query !== '' ? (
								<div className="cursor-default select-none relative py-2 px-4 text-gray-700">Stasjon ikke funnet.</div>
							) : (
								filteredPeople.map((station) => (
									<Combobox.Option key={station.id} className={({ active }) => `cursor-default select-none relative py-2 pl-10 pr-4 ${active ? 'text-white bg-blue-600' : 'text-gray-700'}`} value={station}>
										{({ selected, active }) => (
											<>
												<span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{station.name}</span>
												{selected ? (
													<span className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-blue-600'}`}>
														<CheckIcon className="w-5 h-5" aria-hidden="true" />
													</span>
												) : null}
											</>
										)}
									</Combobox.Option>
								))
							)}
						</Combobox.Options>
					</Transition>
				</div>
			</Combobox>
		</div>
	);
}

export default StationSelect;
