import React from 'react';

function Page() {
	return (
		<div className="grow flex flex-col gap-4 justify-center items-center overflow-hidden">
			<div className="bg-white rounded-md text-slate-800 px-6 py-5 w-full sm:max-w-lg flex flex-col gap-4 justify-center items-center">
				<h1 className="text-xl font-bold text-left w-full">Support</h1>
				<div className="w-full flex flex-col divide-slate-800 gap-6">
					<div className="w-full">
						<p className="text-slate-600 font-semibold mb-4">Dersom du skulle ha spørsmål så kontakter du oss via chatten nederst til høyere eller så kan du sende en e-post til hello@passvarsel.no</p>
						<a href="https://support.passvarsel.no" className="bg-blue-500 text-white px-3 py-2 text-sm shadow-sm rounded-md flex flex-row gap-2 justify-center items-center  mb-4">
							Utforsøk vår helpdesk og få svar på ofte sillte spørsmål
						</a>

						<a href="https://status.passvarsel.no" className="bg-blue-500 text-white px-3 py-2 text-sm shadow-sm rounded-md flex flex-row gap-2 justify-center items-center  mb-4">
							Sjekk statusen på tjenestene våre
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Page;
