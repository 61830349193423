import React, { useState } from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import { BellIcon, ArrowRightIcon, VideoCameraIcon } from '@heroicons/react/solid';
import CreditCard from '../assets/undraw_credit_card_re_blml.svg';
import StripeLogo from '../assets/Powered by Stripe - black.svg';

function Page() {
	const [slideNR, setSlideNR] = useState(1);

	return (
		<div className="grow flex flex-col gap-4 justify-center items-center text-center ">
			{slideNR === 1 ? (
				<div className="flex flex-col gap-4 justify-center items-center text-center ">
					<h1 className="text-5xl font-bold max-w-lg p-2">Passvarsel</h1>
					<p className="text-2xl max-w-2xl">Tjenesten for deg som er lei av å sjekke etter ledige passtimer og for deg som ønsker å kapre avbestilte passtimer. Støtter også time for ID-kort!</p>
					<div className="pt-4 flex flex-col md:flex-row gap-4">
						<Link to="/registrer" className="px-8 py-2.5 flex flex-row gap-2 justify-center items-center text-lg rounded-full font-semibold text-white bg-gradient-to-r from-amber-400 to-amber-500 hover:bg-gradient-to-br focus:ring-4 focus:ring-yellow-800 shadow-lg shadow-yellow-800/20 text-center">
							Sett opp varsling
							<BellIcon className="h-6" />
						</Link>
						<a href="https://www.youtube.com/watch?v=vO-t-0pZq7c" className="px-8 py-2.5 flex flex-row gap-2 justify-center items-center text-lg rounded-full font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-800 shadow-lg shadow-blue-800/20 text-center">
							Se hvordan det fungerer
							<VideoCameraIcon className="h-6" />
						</a>
					</div>
					{/* <div className="pt-4 flex flex-col md:flex-row gap-4">
						<Link to="/registrer" className="px-8 py-2.5 flex flex-row gap-2 justify-center items-center text-lg rounded-full font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-800 shadow-lg shadow-blue-800/20 text-center">
							Se en video om Passvarsel
							<VideoCameraIcon className="h-6" />
						</Link>
					</div> */}
				</div>
			) : (
				<div className="flex flex-col-reverse md:flex-row md:gap-20 justify-center items-center text-center ">
					{slideNR === 2 ? (
						<div className="flex flex-col gap-4 justify-center items-center text-center ">
							<p className="text-2xl max-w-sm font-semibold">Opprett en ny bruker og velg hvilke politistasjoner du ønsker varsler fra.</p>

							<div className="pt-4 flex flex-row gap-4">
								<button onClick={() => setSlideNR(3)} className="px-8 py-2.5 flex flex-row gap-2 justify-center items-center text-lg rounded-full font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-800 shadow-lg shadow-blue-800/20 text-center">
									Neste
									<ArrowRightIcon className="h-6" />
								</button>
							</div>
						</div>
					) : slideNR === 3 ? (
						<div className="flex flex-col gap-4 justify-center items-center text-center ">
							<p className="text-2xl max-w-sm font-semibold">Betal 100kr for å aktivere tjenesten. Du betaler gjennom vår sikre betalingsleverandør.</p>

							<div className="pt-4 flex flex-row gap-4">
								<button onClick={() => setSlideNR(4)} className="px-8 py-2.5 flex flex-row gap-2 justify-center items-center text-lg rounded-full font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-800 shadow-lg shadow-blue-800/20 text-center">
									Neste
									<ArrowRightIcon className="h-6" />
								</button>
							</div>
						</div>
					) : (
						<div className="flex flex-col gap-4 justify-center items-center text-center ">
							<p className="text-2xl max-w-sm font-semibold">Du mottar varsel når timer blir lagt ut eller avbestilt, slik at du kan gå inn og bestille timen.</p>

							<div className="pt-4 flex flex-row gap-4">
								<Link to="/registrer" className="px-8 py-2.5 flex flex-row gap-2 justify-center items-center text-lg rounded-full font-semibold text-white bg-gradient-to-r from-amber-400 to-amber-500 hover:bg-gradient-to-br focus:ring-4 focus:ring-yellow-800 shadow-lg shadow-yellow-800/20 text-center">
									Sett opp varsling
									<BellIcon className="h-6" />
								</Link>
							</div>
						</div>
					)}
					<div className="relative bg-black w-40 md:w-48 rounded-2xl aspect-phone p-1 mb-8 md:mb-0 select-none">
						<div className="relative bg-white h-full w-full rounded-2xl flex flex-col items-center overflow-hidden">
							<div className="grow-1 bg-black rounded-b-xl w-2/4 aspect-phonebar"></div>
							<div className="relative grow w-full flex overflow-hidden transition-transform">
								{slideNR === 2 ? (
									<div className="w-full h-full">
										<div className="absolute max-h-full w-full px-4">
											<h1 className="text-base md:text-xl font-bold text-red-500 text-center mt-5 flex flex-row gap-1 justify-center items-center pr-1">
												<BellIcon className="h-4 md:h-5" />
												Passvarsel
											</h1>
										</div>
										<div className="absolute pt-5 max-h-full w-full aspect-phone flex flex-col justify-center items-center gap-3 px-4 custom-animation-phonesignup">
											<div className="w-full">
												<div className="w-full bg-white text-[0.64rem] text-slate-600 font-semibold flex justify-start items-center pb-0.5">E-post</div>
												<div className="w-full h-[1.4rem] border-2 border-gray-600 bg-gray-100 rounded-md text-slate-500 flex justify-start px-1 py-0.5 items-center">
													<div className="h-[0.33rem] w-0 bg-gray-400 rounded-md animate-pulse custom-animation-phonesignupemail"></div>
												</div>
											</div>
											<div className="w-full">
												<div className="w-full bg-white text-[0.64rem] text-slate-600 font-semibold flex justify-start items-center pb-0.5">Passord</div>
												<div className="w-full h-[1.4rem] border-2 border-gray-600 bg-gray-100 rounded-md text-slate-500 flex justify-start px-1 items-center">
													<div className="relative overflow-hidden h-full w-0 text-[0.9rem] rounded-md animate-pulse custom-animation-phonesignuppassword text-left break-words">*********************</div>
												</div>
											</div>
											<div className="w-max bg-slate-600 border-[1.6px] border-slate-600 rounded-md text-[0.6rem] text-white flex justify-start px-2 py-1 items-center mb-4 custom-animation-phonesignupbutton">Registrer</div>
										</div>

										<div className="absolute pt-5 max-h-full w-full translate-x-full aspect-phone flex flex-col justify-center items-start gap-3 px-3 md:px-4 custom-animation-phoneselectstation">
											<div className="w-full bg-white rounded-md text-[0.66rem] text-slate-700 flex justify-start gap-1 items-center font-bold">Velg politistasjoner</div>
											<div className="relative w-full h-4 pl-5 bg-white rounded-md text-[0.55rem] md:text-[0.64rem] text-slate-500 flex justify-start gap-1 items-center overflow-visible">
												<div className="absolute -left-0 text-green-500 h-3.5 w-3.5 md:h-4 md:w-4 custom-animation-phonestation1green">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<div className="absolute -left-0 text-slate-600 h-3.5 w-3.5 md:h-4 md:w-4 custom-animation-phonestation1gray">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
													</svg>
												</div>
												Grenland politistasjon
											</div>
											<div className="relative w-full h-4 pl-5 bg-white rounded-md text-[0.55rem] md:text-[0.64rem] text-slate-500 flex justify-start gap-1 items-center overflow-visible">
												<div className="absolute -left-0 text-green-500 h-3.5 w-3.5 md:h-4 md:w-4 custom-animation-phonestation2green">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<div className="absolute -left-0 text-slate-600 h-3.5 w-3.5 md:h-4 md:w-4 custom-animation-phonestation2gray">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
													</svg>
												</div>
												Stokke publikumssenter
											</div>
											<div className="relative w-full h-4 pl-5 bg-white rounded-md text-[0.55rem] md:text-[0.64rem] text-slate-500 flex justify-start gap-1 items-center overflow-visible">
												<div className="absolute -left-0 text-green-500 h-3.5 w-3.5 md:h-4 md:w-4 custom-animation-phonestation3green">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
													</svg>
												</div>
												<div className="absolute -left-0 text-slate-600 h-3.5 w-3.5 md:h-4 md:w-4 custom-animation-phonestation3gray">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
													</svg>
												</div>
												Notodden politistasjon
											</div>
											<div className="relative w-full h-4 pl-5 bg-white rounded-md text-[0.55rem] md:text-[0.64rem] text-slate-500 flex justify-start gap-1 items-center overflow-visible">
												<div className="absolute -left-0 text-slate-600 h-3.5 w-3.5 md:h-4 md:w-4">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
													</svg>
												</div>
												Drammen politistasjon
											</div>
											<div className="relative w-full h-4 pl-5 bg-white rounded-md text-[0.55rem] md:text-[0.64rem] text-slate-500 flex justify-start gap-1 items-center overflow-visible">
												<div className="absolute -left-0 text-slate-600 h-3.5 w-3.5 md:h-4 md:w-4">
													<svg xmlns="http://www.w3.org/2000/svg" className="h-full w-full" viewBox="0 0 20 20" fill="currentColor">
														<path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clipRule="evenodd" />
													</svg>
												</div>
												Kongsberg politistasjon
											</div>

											<div className="h-[7%] w-max bg-slate-600 border-[1.6px] border-slate-600 rounded-md text-[0.6rem] text-white flex justify-start px-1.5 items-center mb-4">Lagre</div>
										</div>
									</div>
								) : slideNR === 3 ? (
									<div className="w-full h-full">
										<div className="absolute max-h-full w-full px-4">
											<h1 className="text-xl font-bold text-red-500 text-center mt-5 flex flex-row gap-1 justify-center items-center pr-1">
												<BellIcon className="h-5" />
												Passvarsel
											</h1>
										</div>
										<div className="absolute pt-5 max-h-full w-full aspect-phone flex flex-col justify-center items-center gap-6 px-4">
											<img className="w-5/6" alt="Kredittkort" src={CreditCard} />
											<img className="w-9/12" alt="Stripe Logo" src={StripeLogo} />
										</div>
									</div>
								) : (
									<div className="w-full h-full">
										<div className="absolute max-h-full w-full px-4">
											<h1 className="text-2xl font-bold text-red-500 text-center mt-7 flex flex-row gap-1 justify-center items-center">SMS</h1>
										</div>
										<div className="absolute pt-20 max-h-full w-full aspect-phone flex flex-col justify-start items-left gap-3 px-2">
											<div className="bg-blue-500 text-left rounded-lg px-2.5 py-1.5 text-[0.66rem] custom-animation-message1">
												Nye timer har blitt lagt ut!
												<br />
												Antall nye timer: 5
											</div>
											<div className="bg-blue-500 text-left rounded-lg px-2.5 py-1.5 text-[0.66rem] custom-animation-message2">
												Nye timer har blitt lagt ut!
												<br />
												Antall nye timer: 1
											</div>
											<div className="bg-blue-500 text-left rounded-lg px-2.5 py-1.5 text-[0.66rem] custom-animation-message3">
												Nye timer har blitt lagt ut!
												<br />
												Antall nye timer: 3
											</div>
											<div className="bg-blue-500 text-left rounded-lg px-2.5 py-1.5 text-[0.66rem] custom-animation-message4">
												Nye timer har blitt lagt ut!
												<br />
												Antall nye timer: 7
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Page;
