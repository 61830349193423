import React from 'react';
import { Link } from 'react-router-dom';

function Page() {
	return (
		<div className="grow flex flex-col gap-4 justify-center items-center overflow-hidden">
			<div className="bg-white rounded-md text-slate-800 px-6 py-5 w-full sm:max-w-lg flex flex-col gap-4 justify-center items-center">
				<h1 className="text-xl font-bold text-left w-full">Om Passvarsel</h1>
				<div className="w-full flex flex-col divide-slate-800 gap-6">
					<div className="w-full">
						<h2 className="text-lg font-semibold">Informasjon</h2>
						<p className="text-slate-600 font-semibold mb-4">Mer info kommer snart, skulle du ha spørsmål som haster så sender du en e-post til hello@bergantechnologies.com</p>
						<Link to="/konto" className="text-slate-800 font-semibold mb-4">
							Klikk her for å gå tilbake til konto siden
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Page;
