import React, { useEffect } from 'react';
import './App.css';
import { NavLink, Routes, Route, useNavigate, useLocation, Navigate, Link } from 'react-router-dom';
import { BellIcon } from '@heroicons/react/solid';
import { LogoutIcon } from '@heroicons/react/outline';
import { auth } from './Firebase';
import { signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import HomePage from './pages/Home.jsx';
import LoginPage from './pages/Login.jsx';
import RegisterPage from './pages/Register';
import ResetPasswordPage from './pages/ResetPassword';
import AccountPage from './pages/Konto';
import SupportPage from './pages/Support';
import PersonvernPage from './pages/Personvern';
import AboutPage from './pages/About';

const navigation = [
	/* { name: 'Profiles', url: '/profiles' } */
];

function RequireAuth(props) {
	const [user, loading, error] = useAuthState(auth);
	let location = useLocation();

	if (loading) {
		return (
			<div className="grow flex flex-col gap-1 justify-center">
				<div className="text-center">
					<svg role="status" className="inline mr-2 w-10 h-10 text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
			</div>
		);
	}

	if (error) {
		return (
			<div>
				<p>User auth error</p>
			</div>
		);
	}

	if (!user) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/logginn" state={{ from: location }} replace />;
	}

	return props.children;
}

const CurrentUser = () => {
	const [user, loading, error] = useAuthState(auth);
	let navigate = useNavigate();
	let location = useLocation();

	function signUserIn() {
		navigate('/registrer', { state: { from: location } });
	}

	function signUserOut() {
		signOut(auth)
			.then(() => {
				// Sign-out successful.
			})
			.catch((error) => {
				// An error happened.
			});
	}

	if (loading) {
		return (
			<svg role="status" className="inline mr-2 w-8 h-8 text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
				<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
			</svg>
		);
	}
	if (error) {
		return (
			<div>
				<p>Error</p>
			</div>
		);
	}
	if (user) {
		return (
			<div className="grow-0 flex items-center gap-4 font-bold text-base sm:text-lg ">
				<NavLink to="konto">Min side</NavLink>
				<div className="grow-0 bg-white w-0.5 sm:w-1 h-6 sm:h-8 rounded-full block"></div>
				<button onClick={signUserOut} className="hover:text-blue-500  block">
					<LogoutIcon className="h-6 sm:h-8" />
				</button>
			</div>
		);
	}
	return (
		<button onClick={signUserIn} className="grow-0 font-bold text-lg">
			Min side
		</button>
	);
};

function App() {
	useEffect(() => {
		function setViewHeight() {
			const { innerHeight: height } = window;
			document.documentElement.style.setProperty('--screenfull', `${Math.floor(height) - 1}px`);
		}

		setViewHeight();

		window.addEventListener('resize', setViewHeight);
	}, []);

	return (
		<div className="min-h-screen bg-gradient-to-tr from-red-800 via-red-600 to-red-800">
			<div className="min-h-screen h-max text-white flex flex-col py-8 px-8 md:py-12 gap-12 md:px-20">
				<header className="grow-0 flex font-bold text-base sm:text-lg items-center gap-4">
					<div className="grow flex items-center gap-8">
						<Link to="/" className="grow-0 flex flex-row gap-2 justify-center items-center">
							<BellIcon className="h-5 sm:h-6" />
							Passvarsel
						</Link>
						<div className="grow flex items-center gap-4">
							{navigation.map((item) => (
								<NavLink to={item.url} key={item.name} className={({ isActive }) => '' + (isActive ? ' text-blue-400' : 'text-white')}>
									{item.name}
								</NavLink>
							))}
						</div>
					</div>
					<CurrentUser />
				</header>
				<Routes>
					<Route path="/" element={<HomePage />} />
					<Route
						path="konto"
						element={
							<RequireAuth>
								<AccountPage />
							</RequireAuth>
						}
					/>
					<Route path="logginn/*" element={<LoginPage />} />
					<Route path="registrer/*" element={<RegisterPage />} />
					<Route path="tilbakestill/*" element={<ResetPasswordPage />} />
					<Route path="support" element={<SupportPage />} />
					<Route path="personvern" element={<PersonvernPage />} />
					<Route path="om" element={<AboutPage />} />
				</Routes>
				<footer className="grow-0 flex flex-col gap-4 justify-center items-center overflow-hidden">
					<div className="bg-white rounded-md text-slate-800 px-4 py-3 w-full sm:max-w-lg flex flex-col sm:flex-row gap-2 justify-center items-center text-sm">
						<Link to="/personvern" className="font-bold text-left  w-full underline">
							Personvernerklæring
						</Link>
						<a href="https://bergantechnologies.com" className="font-semibold text-left w-full underline sm:text-right">
							© {new Date().getFullYear()} Bergan Technologies AS
						</a>
					</div>
				</footer>
			</div>
		</div>
	);
}

export default App;
