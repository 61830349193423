import React, { Fragment, useState, useEffect } from 'react';
import { CreditCardIcon, ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import { doc, Timestamp } from 'firebase/firestore';
import { auth, firestore, functions } from '../Firebase';
import { httpsCallable } from 'firebase/functions';
import StationSelector from '../components/StationSelector.jsx';
import PaymentModal from '../components/PaymentModal';
import PhoneVerificationModal from '../components/PhoneVerificationModal';
import ToggleInput from '../components/ToggleInput';

function AccountPage(props) {
	let user = props.currentUser;

	const [notification, setNotification] = useState('');
	const [showNotification, setShowNotification] = useState(false);

	const [userdoc, userdataLoading, userdataError] = useDocument(doc(firestore, 'users', user.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	const [paymentModalOpen, setPaymentModalOpen] = useState(false);
	const [phoneVerificationModalOpen, setPhoneVerificationModalOpen] = useState(false);

	const [enabled, setEnabled] = useState(userdoc && userdataLoading === false && !(userdoc === undefined) ? userdoc.data().notify : false);
	const [notificationDate, setNotificationDate] = useState(userdoc && userdataLoading === false && !(userdoc === undefined) ? userdoc.data().notifyBefore.toDate() : new Date());

	useEffect(() => {
		setEnabled(userdoc && userdataLoading === false && !(userdoc === undefined) ? userdoc.data().notify : false);
		setNotificationDate(userdoc && userdataLoading === false && !(userdoc === undefined) ? userdoc.data().notifyBefore.toDate() : new Date());
	}, [userdoc, userdataLoading]);

	function stationSelected(stationNR, oldStationID, newStationID) {
		if (oldStationID === newStationID) {
		} else {
			var changeStation = httpsCallable(functions, 'changeStation');
			console.log({ station: newStationID, stationNR: stationNR });
			changeStation({ station: newStationID, stationNR: stationNR }).then((result) => {
				console.log(result);

				setNotification('Stasjonsliste oppdatert');
				setShowNotification(true);
			});
		}
	}

	function sendNotifications(value) {
		var changeStation = httpsCallable(functions, 'toggleNotification');
		changeStation({ notify: value }).then((result) => {
			console.log(result);
			setNotification(value ? 'Varsler ble slått på' : 'Varsler ble slått av');
			setShowNotification(true);
		});
	}

	function triggerRefill() {
		auth.currentUser
			.getIdToken(/* forceRefresh */ true)
			.then(function (idToken) {
				var url = 'https://us-central1-passvarsel-c6778.cloudfunctions.net/startrefill?userToken=' + idToken;
				window.location.href = url;
			})
			.catch(function (error) {
				// Handle error
			});
	}

	function toISODate(date) {
		return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
	}

	function sendNotificationDate(value) {
		var setNotificationDate = httpsCallable(functions, 'setNotificationDate');
		setNotificationDate({ notificationDate: toISODate(value) }).then((result) => {
			console.log(result);
			setNotification('Varsel dato oppdatert');
			setShowNotification(true);
		});
	}

	function maxDate() {
		var date = new Date();
		date.setMonth(4 + date.getMonth());
		return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
	}

	function minDate() {
		var date = new Date();
		return `${date.getFullYear()}-${(date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}-${date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}`;
	}

	if (userdataLoading) {
		return (
			<div className="grow flex flex-col gap-4 justify-center items-center">
				<div className="text-center">
					<svg role="status" className="inline mr-2 w-10 h-10 text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
			</div>
		);
	}
	if (userdataError) {
		return (
			<div className="grow flex flex-col gap-1 justify-center">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-2 text-center text-3xl font-extrabold text-white">Error: {userdataError.message}</h2>
				</div>
			</div>
		);
	}
	if (userdoc && userdoc.data()) {
		return (
			<div className="grow flex flex-col gap-4 justify-center items-center overflow-hidden">
				<>
					{/* Global notification live region, render this permanently at the end of the document */}
					<div aria-live="assertive" className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start">
						<div className="w-full flex flex-col items-center space-y-4 sm:items-end">
							{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
							<Transition show={showNotification} as={Fragment} enter="transform ease-out duration-300 transition" enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enterTo="translate-y-0 opacity-100 sm:translate-x-0" leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
								<div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
									<div className="p-4">
										<div className="flex items-start">
											<div className="flex-shrink-0">
												<CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
											</div>
											<div className="ml-3 w-0 flex-1 pt-0.5">
												<p className="text-sm font-medium text-gray-900">{notification}</p>
											</div>
											<div className="ml-4 flex-shrink-0 flex">
												<button
													className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
													onClick={() => {
														setShowNotification(false);
													}}
												>
													<span className="sr-only">Close</span>
													<XIcon className="h-5 w-5" aria-hidden="true" />
												</button>
											</div>
										</div>
									</div>
								</div>
							</Transition>
						</div>
					</div>
				</>
				<PaymentModal open={paymentModalOpen} setOpen={(value) => setPaymentModalOpen(value)} />
				<PhoneVerificationModal open={phoneVerificationModalOpen} setOpen={(value) => setPhoneVerificationModalOpen(value)} />
				<div className="bg-white rounded-md text-slate-800 px-6 py-5 w-full sm:max-w-lg flex flex-col gap-4 justify-center items-center">
					<h1 className="text-xl font-bold text-left w-full">Min side</h1>
					<div className="w-full flex flex-col divide-slate-800 gap-6">
						<div className="w-full">
							<h2 className="text-lg font-semibold">Informasjon</h2>
							<p className="text-slate-600 font-semibold mb-4">Her finner du informasjon om din konto</p>
							<label htmlFor="email" className="block text-sm font-medium text-slate-700 mb-1">
								E-post
							</label>
							<input id="email" name="email" type="email" placeholder="E-post" value={user.email} required disabled className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm " />

							<label htmlFor="customerid" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
								Kunde ID
							</label>
							<input id="customerid" name="customerid" type="text" placeholder="Kunde ID" value={user.uid} required disabled className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm " />
							{userdoc.data().active === false || !('active' in userdoc.data()) || (userdoc.data().active instanceof Timestamp && userdoc.data().active.toDate() < new Date()) ? (
								<></>
							) : (
								<div>
									<label htmlFor="activeuntil" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
										Tjeneste utløper
									</label>
									<input id="activeuntil" name="activeuntil" type="date" placeholder="Utløpsdato" value={toISODate(userdoc.data().active.toDate())} required disabled className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm " />
									<label htmlFor="notificationsLeft" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
										Varsler igjen
									</label>
									<div className="flex flex-row gap-4">
										<input id="notificationsLeft" name="notificationsLeft" type="text" placeholder="Varsler igjen" value={`${userdoc.data().notificationsLeft}`} required disabled className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm " />
										<button onClick={triggerRefill} className="bg-blue-500 text-white px-3 py-2 text-sm shadow-sm rounded-md flex flex-row gap-2 justify-center items-center min-w-max">
											Fyll på varsler
											<CreditCardIcon className="h-5" />
										</button>
									</div>
								</div>
							)}
						</div>
						{userdoc.data().active === false || !('active' in userdoc.data()) || (userdoc.data().active instanceof Timestamp && userdoc.data().active.toDate() < new Date()) ? (
							<button id="paymentstatus" onClick={() => setPaymentModalOpen(true)} className="bg-blue-500 text-white px-3 py-2 text-sm shadow-sm rounded-md flex flex-row gap-2 justify-center items-center mt-3">
								Betal og aktiver kontoen
								<CreditCardIcon className="h-5" />
							</button>
						) : (
							<div className="w-full">
								<h2 className="text-lg font-semibold">Varselinnstillinger</h2>
								<p className="text-slate-600 font-semibold mb-4">Velg din politistasjon, slå av og på varsler</p>

								<div>
									<label htmlFor="station1select" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
										Stasjon 1
									</label>
									<StationSelector id="station1select" name="station1select" onSelect={(e) => stationSelected(0, userdoc.data().branches[0], e)} currentStation={userdoc.data().branches[0]} />
								</div>
								{userdoc.data().branches[0] ? (
									<div>
										<label htmlFor="station1select" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
											Stasjon 2
										</label>
										<StationSelector id="station1select" name="station1select" onSelect={(e) => stationSelected(1, userdoc.data().branches[1], e)} currentStation={userdoc.data().branches[1] ? userdoc.data().branches[1] : null} />
									</div>
								) : (
									<></>
								)}
								<label htmlFor="notificationdate" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
									Motta varsler om passtimer før...
								</label>
								<input
									id="notificationdate"
									name="notificationdate"
									type="date"
									value={toISODate(notificationDate)}
									onChange={(val) => {
										setNotificationDate(new Date(val.target.value));
										sendNotificationDate(new Date(val.target.value));
									}}
									min={minDate()}
									max={maxDate()}
									required
									className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full pl-3 py-2 pr-1.5  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm "
								/>
								{userdoc.data().phone ? (
									<div>
										<label htmlFor="phonenumber" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
											Telefonnummer
										</label>
										<input id="phonenumber" name="phonenumber" type="tel" placeholder="Telefonnummer" value={userdoc.data().phone.toString().substring(2)} pattern="[0-9]{3} [0-9]{2} [0-9]{3}" maxLength={8} disabled required className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm " />
									</div>
								) : (
									<button onClick={() => setPhoneVerificationModalOpen(true)} className="grow-0 mt-3 bg-blue-500 text-white px-3 py-2 text-sm shadow-sm rounded-md h-max mb-[1px] focus:ring-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2">
										Legg til telefonnummer
									</button>
								)}

								<label htmlFor="sendnotifications" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
									Motta varsler
								</label>
								<ToggleInput
									id="sendnotifications"
									name="sendnotifications"
									enabled={enabled}
									setEnabled={(val) => {
										setEnabled(val);
										sendNotifications(val);
									}}
									disabled={userdoc.data().active === false}
								/>
							</div>
						)}
						<div className="w-full">
							<h2 className="text-lg font-semibold">Support</h2>
							<Link to="/support" className="text-slate-600 font-semibold mb-4 flex flex-row gap-2 justify-start items-center ">
								Kontaktinfo og ofte stillte spørsmål <ArrowRightIcon className="h-5" />
							</Link>
						</div>
						{/* <div className="w-full">
							<h2 className="text-lg font-semibold">Telefonnummere</h2>
							<p className="text-slate-600 font-semibold mb-4">Her er en oversikt over dine telefonnummer</p>
							<div className="flex flex-col">
								<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
									<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
										<div className=" overflow-hidden border border-gray-200 sm:rounded-md">
											<table className="min-w-full divide-y divide-gray-200">
												<thead className="bg-blue-200">
													<tr>
														<th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-slate-800 uppercase tracking-wider">
															Telefonnummer
														</th>
														<th scope="col" className="px-6 py-3 text-left text-xs font-semibold text-slate-800 uppercase tracking-wider">
															Status
														</th>
														<th scope="col" className="relative px-6 py-3">
															<span className="sr-only">Verifiser</span>
														</th>
													</tr>
												</thead>
												<tbody className="bg-white divide-y divide-gray-200">
													{phonenumbers.map((person) => (
														<tr key={person.name}>
															<td className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
															<td className="px-6 py-3 whitespace-nowrap text-sm text-gray-500"> {person.name === '+47 480 46 607' ? <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">Må verifiseres</span> : <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Verifisert</span>}</td>
															<td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
																{person.name === '+47 480 46 607' ? (
																	<a href="#" className="text-blue-600 hover:text-blue-900">
																		Verifiser
																	</a>
																) : (
																	<></>
																)}
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
							<div className="flex flex-row gap-4 items-end">
								<div>
									<label htmlFor="phonenumber" className="block text-sm font-medium text-slate-700 mt-3 mb-1">
										Telefonnummer
									</label>
									<input id="phonenumber" name="phonenumber" type="tel" placeholder="Telefonnummer" pattern="[0-9]{3} [0-9]{2} [0-9]{3}" maxLength={8} required className="appearance-none border border-gray-300 bg-white disabled:bg-gray-200 rounded-md block w-full px-3 py-2  shadow-sm placeholder-slate-500 text-slate-700 outline-none text-sm " />
								</div>

								<button className="bg-blue-500 text-white px-3 py-2 text-sm shadow-sm rounded-md h-max mb-[1px] focus:ring-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2">Legg til</button>
							</div>
						</div> */}
					</div>
				</div>
			</div>
		);
	}

	return <p>Hvis du ser dette bør du nok laste inn siden på nytt.</p>;
}

function Page() {
	const [user, authLoading, authError] = useAuthState(auth);

	if (authLoading) {
		return (
			<div className="grow flex flex-col gap-4 justify-center items-center">
				<div className="text-center">
					<svg role="status" className="inline mr-2 w-10 h-10 text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
			</div>
		);
	}
	if (authError) {
		return (
			<div className="grow flex flex-col gap-1 justify-center">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-2 text-center text-3xl font-extrabold text-white">Error: {authError.message}</h2>
				</div>
			</div>
		);
	}
	if (user) {
		return <AccountPage currentUser={user} />;
	}

	return <p>Hvis du ser dette, ja da er noe ordentlig galt</p>;
}

export default Page;
