import React, { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CreditCardIcon } from '@heroicons/react/outline';
import { auth } from '../Firebase';

function Component(props) {
	const cancelButtonRef = useRef(null);

	function triggerPayment() {
		auth.currentUser
			.getIdToken(/* forceRefresh */ true)
			.then(function (idToken) {
				var url = 'https://us-central1-passvarsel-c6778.cloudfunctions.net/startpayment?userToken=' + idToken;
				window.location.href = url;
			})
			.catch(function (error) {
				// Handle error
			});
	}

	return (
		<Transition show={props.open} as={Fragment}>
			<Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} open={props.open} onClose={() => props.setOpen(false)}>
				<div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0" enterTo="opacity-100" leave="ease-in duration-200" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Dialog.Overlay className="fixed inset-0 bg-slate-800 bg-opacity-75 transition-all backdrop-blur-sm" />
					</Transition.Child>

					{/* This element is to trick the browser into centering the modal contents. */}
					<span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
						&#8203;
					</span>
					<Transition.Child as={Fragment} enter="ease-out duration-300" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="ease-in duration-200" leaveFrom="opacity-100 scale-100" leaveTo="opacity-0 scale-95">
						<div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
							<div className="sm:flex sm:items-start">
								<div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
									<CreditCardIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
								</div>
								<div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
									<Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
										Informasjon
									</Dialog.Title>
									<div className="mt-2">
										<p className="text-sm text-gray-500">
											Ved å betale vil du få varsler når nye timer legges ut eller når det blir gjort avbestillinger. Du kan maksimum få varsler fra 2 politistasjoner. Du er selv ansvarlig for å gå inn på politiet's side og bestille den ledige timen. <span className="underline">Dette er kun en varslingstjeneste.</span> Vi kan ikke garantere at du får en passtime da det krever at du selv er rask og bestiller timen. Dersom andre har valgt samme politistasjon som deg, vil også de bli varslet samtidig. Tjenesen er aktiv i 30 dager fra betalingstidspunkt. Du kan stoppe varslene på min side, hvis ikke vil varslene stoppe etter 30 dager. Tjenesten inkluderer 100 varsler.{' '}
										</p>
									</div>
								</div>
							</div>
							<div className="mt-5 sm:mt-4 sm:ml-10 sm:pl-4 sm:flex">
								<button type="button" className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:w-auto sm:text-sm" onClick={triggerPayment}>
									Godta og betal
								</button>
								<button type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm" onClick={() => props.setOpen(false)} ref={cancelButtonRef}>
									Avbryt
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}

export default Component;
