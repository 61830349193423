import React, { useState } from 'react';
import { XCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { Link, useLocation, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../Firebase';

function UserLoggedIn() {
	let location = useLocation();
	let from = location.state && location.state.from && location.state.from.pathname ? location.state.from.pathname : '/konto';

	return (
		<div className="grow flex flex-col gap-4 justify-center items-center">
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<div className="text-center">
					<Navigate to={from} replace />
					<svg role="status" className="inline mr-2 w-10 h-10 text-gray-200 animate-spin dark:text-gray-600 fill-rose-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
			</div>
		</div>
	);
}

const SignInForm = () => {
	const [resetMessage, setResetMessage] = useState(null);

	function handleSignup(event) {
		event.preventDefault();
		sendPasswordResetEmail(auth, event.target.email.value)
			.then(() => {
				setResetMessage({ success: true, message: 'E-post er sendt for å tilbakestille passordet' });
			})
			.catch((error) => {
				setResetMessage({ success: false, message: error.message, code: error.code });
			});
	}

	return (
		<div className="grow flex flex-col gap-2 justify-center items-center">
			<h2 className="text-center text-4xl font-extrabold text-white">Tilbakestillng av passord</h2>
			<p className="mt-1 text-center text-sm text-gray-100">
				Er du på feil sted?{' '}
				<Link to="/logginn" replace className="font-semibold underline underline-offset-2 decoration-blue-400  hover:text-blue-400">
					Gå tilbake til innlogging
				</Link>
			</p>
			<div className="sm:mx-auto sm:w-full sm:max-w-md">
				<div className="bg-transparent py-4 px-4 rounded-xl sm:px-10 space-y-5">
					<form className="space-y-5" onSubmit={handleSignup}>
						<div className="border border-gray-300 bg-white rounded-md divide-y divide-gray-300 text-slate-800">
							<input id="email" name="email" type="email" placeholder="E-post" autoComplete="email" required className="appearance-none bg-transparent block w-full px-3 py-2  shadow-sm placeholder-gray-400 outline-none sm:text-sm " />
						</div>

						<div>
							<button type="submit" className="w-full flex justify-center py-2 px-3.5 rounded-md text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:ring-blue-800 shadow-lg shadow-blue-800/40">
								Tilbakestill passord
							</button>
						</div>
						{resetMessage ? (
							<div className="pt-2">
								<div className="rounded-md bg-white p-3">
									{resetMessage.success ? (
										<div className="flex items-center">
											<div className="flex-shrink-0">
												<CheckCircleIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
											</div>
											<div className="ml-3">
												<p className="text-sm font-medium text-green-700">{resetMessage.message}</p>
											</div>
										</div>
									) : (
										<div className="flex items-center">
											<div className="flex-shrink-0">
												<XCircleIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
											</div>
											<div className="ml-3">
												<p className="text-sm font-medium text-red-700">{resetMessage.code === 'auth/user-not-found' ? 'Det finnes ingen bruker med den e-post addressen.' : resetMessage.code === 'auth/invalid-email' ? 'E-postadreessen er ugyldig.' : resetMessage.message}</p>
											</div>
										</div>
									)}
								</div>
							</div>
						) : (
							<></>
						)}
					</form>

					<div className="mt-6"></div>
				</div>
			</div>
		</div>
	);
};

const Page = () => {
	const [user, loading, error] = useAuthState(auth);

	if (loading) {
		return (
			<div className="grow flex flex-col gap-4 justify-center items-center">
				<div className="text-center">
					<svg role="status" className="inline mr-2 w-10 h-10 text-white animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
			</div>
		);
	}
	if (error) {
		return (
			<div className="grow flex flex-col gap-4 justify-center items-center">
				<div className="sm:mx-auto sm:w-full sm:max-w-md">
					<h2 className="mt-2 text-center text-3xl font-extrabold text-white">Error: {error.message}</h2>
				</div>
			</div>
		);
	}
	if (user) {
		return <UserLoggedIn />;
	}
	return <SignInForm />;
};

export default Page;
